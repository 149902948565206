<template>
  <div
    id="langing-page"
    class="d-flex pt-5 pb-2 px-4 flex-column justify-content-between h-100"
  >
    <div class="py-3 text-center">
      <img src="../assets/img/logo.svg" height="150" />
    </div>
    <div class="text-center px-5 py-3 text-white">
      <strong class="d-block">Chaque Jour</strong> des découvertes des Bons
      Plans, des plats différents
    </div>
    <b-btn :to="target" variant="outline-primary"> découvrir </b-btn>
  </div>
</template>
<script>
export default {
  computed: {
    target: function () {
      if (this.authenticated) {
        return { name: "list" };
      }
      return { name: "public.login" };
    },
  },
};
</script>
